.Table-container {
    overflow-x: auto; /* Needed for table to be responsive */
  }
  
  .Table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .Table-head {
    background-color: #0c2033;
  }
  
  .Table-head tr th {
    padding: 10px 20px;
    color: #9cb3c9;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
  }
  
  .Table-body {
    text-align: left;
    background-color: #0f273d;
  }
  
  .Table-body tr td {
    padding: 24px 20px;
    border-bottom: 2px solid #0c2033;
    color: #fff;
    cursor: pointer;
  }
  
  .Table-rank {
    color: #9cb3c9;
    margin-right: 18px;
    font-size: 12px;
  }
  
  .Table-dollar {
    color: #9cb3c9;
    margin-right: 6px;
  }
  