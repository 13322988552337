.Detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 0 60px;
  }
  
  .Detail-heading {
    font-size: 24px;
    font-weight: 300;
  }
  
  .Detail-container {
    width: 100%;
    max-width: 400px;
    margin-top: 30px;
    padding: 40px 40px 0;
    border-radius: 4px;
    box-shadow: 0px 0px 40px 0px#1f364d;
  }
  
  .Detail-item {
    margin-bottom: 50px;
  }
  
  .Detail-value {
    border-radius: 20px;
    background-color: #1f364d;
    font-size: 14px;
    padding: 8px 12px;
    margin-left: 10px;
  }
  
  .Detail-title {
    display: block;
    color: #9cb3c9;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .Detail-dollar {
    color: #9cb3c9;
    margin-right: 6px;
  }
  